@charset "utf-8";


/*------------------CUSTOM CSS---------------------*/



.hero {
  background-color:#1e46699e;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border-bottom:3px #29d8c9 solid;
  box-shadow:#00000080 5px 5px 15px;
}

.octoreal {
  width:180px;
  width:calc(120px + 15%);
  height:auto;
  position:absolute;
  bottom:0;
  right:calc(100vw - 95%);
  z-index:-1;
}
