@charset "utf-8";

// COLORS
$theme-colors: (
  "primary": #326fA5,
  "primarylite": #326fa585,
  "secondary": #6c757d,
  "tirtiary": #a3afb9,
  "tirtiarylite": #a3afb980,
  "success": #28a745,
  "danger": #f25f42,
  "warning": #fd8718,
  "pink": #e14186,
  "purple": #873dd1,
  "info": #29d8c9,
  "infolite": #29d8c982,
  "light": #f8f9fa,
  "dark": #24282c,
  "white": #ffffff,
  "whitelite": #eaeaeadc,
  "black": #000000,
  "blacklite": #000000e7,
  "transparent": transparent,
);


// TEXT
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;

// BREAKPOINTS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);



// IMPORT BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// IMPORT FONT AWESOME
@import url("https://use.fontawesome.com/releases/v5.10.1/css/all.css");



#root {
  // Full height of screen and flex so that we can push footer
  // to the bottom even if content is shorter than screen
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // Push footer to the bottom of the screen
  > .footer {
    margin-top: auto;
  }
  // Prevent child elements from shrinking when content
  // is taller than the screen (quirk of having flex parent)
  > * {
    flex-shrink: 0;
  }
}

/*------------------CUSTOM CSS---------------------*/
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.center {
  margin-left:auto;
  margin-right:auto;
  left:0;
  right:0;
  text-align:center;
}

.absolute {
  position:absolute;
}

.fixed {
  position:fixed;
}

.blackbkg {
  background-color: #196798!important;
  z-index:-7;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width:100%;
  height:100%;
  display:block;

}

.bg-whitelite {
  background-color: #eaeaeadc,
}

.round1 {
  border-radius:20px!important;
}

.glass-card-teal {
  background-color:#29d8c928;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(9px);
  border:1px #ffffff solid;
  box-shadow:#00000080 5px 5px 15px;
  border-radius:20px;
  
}

.glass-card-blue {
  background-color:#326fa5;
  // backdrop-filter: blur(5px);
  // -webkit-backdrop-filter: blur(9px);
  // border:1px #29d8c9 solid;
  // box-shadow:#00000080 5px 5px 15px;
  border-radius:20px;
  width:80%;
  margin-left:auto;
  margin-right:auto;
  margin-top:80px;
}

.ghost-card-teal {
  background-color:#29d8c982;
  filter: blur(33px);
  -webkit-filter: blur(33px);
  border:1px #ffffff solid;
  box-shadow:#00000080 5px 5px 15px;
  border-radius:20px;
  min-width:90%;
  height:200px;
  position:absolute;
  left:2%;
  right:2%;
  top:calc(-5% - 1em);
  z-index:-1;
}

.ghost-card-blk {
  background-color:#00000094;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(9px);
  border:1px #ffffff solid;
  box-shadow:#00000080 5px 5px 15px;
  border-radius:20px;
}

.citybkg {
  position:fixed;
  left:0;
  bottom:-5%;
  // bottom:calc(1px - 25%);
  z-index:-5;
}

.cityforeground {
  position:fixed;
  left:0;
  bottom:-5%;
  z-index:5;
  filter:blur(1px);
  
}

.textshadow {
  text-shadow:3px 1px 5px black;
}

.shadow1 {
  box-shadow:#00000080 5px 5px 15px;
}


.sectionheader {
  color:#fd8718;
  
}

.btn-lg,.btn-group-lg>.btn
{
  box-shadow: 3px 3px 3px 3px #29292954,
   -2px -2px 2px 2px #ffffff28;
}
.btn-lg:focus,.btn-group-lg>.btn
{
  box-shadow: 1px 1px 1px 1px #29292954,
   -1px -1px 1px 1px #ffffff28;
}
.btn-lg:hover,.btn-group-lg>.btn
{
  box-shadow: 1px 1px 1px 1px #29292954,
  -1px -1px 1px 1px #ffffff28;
}

.neubtn {
       
  box-shadow: inset 2px 3px 0px 2px #d5d5d5a1,
   inset -2px -3px 0px 2px #ffffffa1;
  background: #eddfdf;
}

.neubtn:hover {
     
box-shadow: inset 3px 4px 1px 2px #d5d5d5a1,
 inset -3px -4px 1px 2px #ffffffa1;
background: #eddfdf;
}

.neubtn:focus {
     
box-shadow: inset 3px 4px 1px 2px #d5d5d5a1,
 inset -3px -4px 1px 2px #ffffffa1;
background: #eddfdf;
}

/*------------live grdadient------------*/
.live-gradient {
  animation: Gradient 45s ease-in-out infinite;
  background: linear-gradient(170deg,#69bef3,#1e9eee,#2180bc,#1a72ba,#114388,#91691e,#853d3d,#341b4c,#000,#000);
  background-size: 1500vw 100vh;
  background-repeat:no-repeat;
}


@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/*-----------bkg clouds------------*/


@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
  from {background-position: 0;}
  to {background-position:10000px 0;}
}

.clouds {
position:fixed;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
}

.clouds{
  background:url(https://kickapps.io/media/images/sky/clouds2.png), url(https://kickapps.io/media/images/sky/clouds1.png);
  background:transparent top center, transparent middle left;
  background-repeat: no-repeat, repeat;
  z-index:-6;
  zoom:1.3;
  -moz-animation:move-clouds-back 400s linear infinite;
  -ms-animation:move-clouds-back 400s linear infinite;
  -o-animation:move-clouds-back 400s linear infinite;
  -webkit-animation:move-clouds-back 400s linear infinite;
  animation:move-clouds-back 400s linear infinite;
}


@media screen and (min-width: 576px) and (max-width: 768px) {
  .citybkg {
   
    bottom:-7%;
    
  }
  
  .cityforeground {
 
    bottom:-7%;
    
    filter:blur(1.25px);
  }

}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .citybkg {
  
    bottom:-13%;
    
  }
  
  .cityforeground {
   
    bottom:-13%;
   
    filter:blur(1.5px);
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .citybkg {
  
    bottom:-15%;
    
  }
  
  .cityforeground {
   
    bottom:-15%;
   
    filter:blur(1.75px);
  }
}

@media screen and (min-width: 1201px) and (max-width: 1500px){
  .citybkg {
  
    bottom:-18%;
   
  }
  
  .cityforeground {
   
    bottom:-18%;
   
    filter:blur(2px);
  }
}

@media screen and (min-width: 1501px) and (max-width: 1800px){
  .citybkg {
  
    bottom:-23%;
   
  }
  
  .cityforeground {
   
    bottom:-23%;
   
    filter:blur(2.25px);
  }
}

@media screen and (min-width: 1801px) and (max-width: 2400px){
  .citybkg {
  
    bottom:-28%;
   
  }
  
  .cityforeground {
   
    bottom:-28%;
   
    filter:blur(2.5px);
  }
}

@media screen and (min-width: 2401px){
  .citybkg {
  
    bottom:-35%;
   
  }
  
  .cityforeground {
   
    bottom:-35%;
   
    filter:blur(2.75px);
  }
}